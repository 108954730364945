import { get, set } from 'lodash';

/**object example
{
  title: 'testTitle',
  field: {
    internalField: {
      key1: 'value',
    },
  }
}
config example
{ fieldNameForNewObject: 'field.internalField.key1' }
fieldNameForNewObject – new key for object we return
value for fieldNameForNewObject – path in object we pass */
export function mapFieldsByConfig<T>(
  object: T,
  config: Record<string, string>,
): Record<string, T> {
  const result: Record<string, T> = {};

  Object.entries(config).forEach(([newKey, path]) => {
    const value = get(object, path);
    if (value !== undefined) {
      set(result, newKey, value);
    }
  });

  return result;
}
