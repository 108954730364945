import { Injectable } from '@angular/core';
import {
  IMediaCapturer,
  IUploadResult,
  MediaType,
  RecordStatus,
} from './types';
import { VideoCapturer } from './video-capturer';
import { AudioCapturer } from './audio-capturer';
import { BehaviorSubject, Subject } from 'rxjs';
import { DisposeBag } from '@utils/DisposeBag';
import { map } from 'rxjs/operators';
import { HttpService } from '@core/http';

@Injectable({
  providedIn: 'root',
})
export class MediaCaptureService extends DisposeBag implements IMediaCapturer {
  static getId(url: string): string | undefined {
    const regex = /(\d+)/;
    return url.match(regex)[0];
  }

  private activeMediaCapturer: IMediaCapturer;

  private mediaCapturer = {
    [MediaType.VIDEO]: VideoCapturer,
    [MediaType.AUDIO]: AudioCapturer,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _error$ = new Subject<any>();
  private _recordStatus$ = new BehaviorSubject<RecordStatus>('none');
  private _uploadResult$ = new Subject<IUploadResult | undefined>();
  constructor(private httpService: HttpService<IUploadResult>) {
    super();
    this.activeMediaCapturer = new VideoCapturer(httpService);
  }

  get mediaCapturerType(): MediaType | undefined {
    if (this.activeMediaCapturer instanceof VideoCapturer)
      return MediaType.VIDEO;
    if (this.activeMediaCapturer instanceof AudioCapturer)
      return MediaType.AUDIO;

    return undefined;
  }

  get uploadResult$() {
    return this._uploadResult$;
  }

  get recordStatus$() {
    return this._recordStatus$;
  }

  get error$() {
    return this._error$;
  }

  get isInProgress$() {
    return this._recordStatus$.pipe(map((status) => status === 'inprogress'));
  }

  public isRecordingInProgress(): boolean {
    return this.activeMediaCapturer?.recordStatus$.getValue() === 'inprogress';
  }

  public async startRecord(type: MediaType = MediaType.VIDEO) {
    const CapturerToInit = this.mediaCapturer[type];
    /** In case if some record already in progress we want to stop record */
    if (this.activeMediaCapturer?.recordStatus$.getValue() === 'inprogress') {
      this.stopRecord();
      return;
    } else {
      this.activeMediaCapturer = new CapturerToInit(this.httpService);
      this.subscribeToInstance();
    }

    return this.activeMediaCapturer.startRecord();
  }

  public stopRecord(): void {
    return this.activeMediaCapturer.stopRecord();
  }

  public destroy() {
    this.dispose();
  }

  public getMedia(id: string) {
    return this.activeMediaCapturer.getMedia(id);
  }

  private subscribeToInstance() {
    this.dispose();
    this.add(this.activeMediaCapturer.error$.subscribe(this._error$));
    this.add(
      this.activeMediaCapturer.recordStatus$.subscribe(this._recordStatus$),
    );
    this.add(
      this.activeMediaCapturer.uploadResult$.subscribe(this._uploadResult$),
    );
  }
}
