import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { WindowRefService } from './window-ref.service';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  private history: string[] = [];
  private _currentUrl = new BehaviorSubject<string>('');
  constructor(
    private windowRefService: WindowRefService,
    private location: Location,
    private router: Router,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
        this._currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  public get currentUrl() {
    return this._currentUrl;
  }

  public isSafari = (): boolean => {
    const userAgent = navigator.userAgent.toLowerCase();

    return (
      userAgent.indexOf('safari') !== -1 && !(userAgent.indexOf('chrome') > -1)
    );
  };

  public navigateBack() {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
      return;
    }

    if (this.windowRefService.nativeWindow.history.length > 0) {
      this.location.back();
      return;
    }
    this.router.navigateByUrl('/');
  }
}
