import {
  Component, ElementRef,
  Input, OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import ISection from '@models/section';
import BasicRxComponent from '@components/BasicRxComponent';
import { BehaviorSubject } from 'rxjs';
import { ModalsService } from '@components/modals/modals.service';
import { createChatbotUrl } from '@utils/urlFactory';
import { IChapter } from '@models/chapter';
import { HttpService } from '@core/http';
import { HttpStatusCode } from '@angular/common/http';
import { AiMessage, ChatRequest, ChatSystemMessage, ChatTarget, Feedback } from './chatbot.types';
import { formatAiMessage } from './chatbot.utils';
import { CourseService } from '@services/course.service';
import { CourseNavigationContext } from '@services/chapter-navigation.service';
import { IColumn, ISearchResult } from '@models/course';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ChatbotComponent
  extends BasicRxComponent
  implements OnInit
{
  @Input() public section: ISection;
  @Input() public chapter: IChapter;
  @Input() public readonlyMessages: AiMessage[];

  @ViewChild('input') inputElement!: ElementRef;

  public userInput: string;
  public messages = new BehaviorSubject<AiMessage[]>([]);
  public isReceivingMessage = new BehaviorSubject(false);
  public sessionId: string = '';
  public messageLimitReached = false;
  public showChatControls$ = new BehaviorSubject(false);
  public feedback$ = new BehaviorSubject<Feedback>(null);

  constructor(
    private client: HttpService<unknown>,
    public modalsService: ModalsService,
    public courseService: CourseService,
    public nav: CourseNavigationContext,
  ) {
    super();
  }

  public get isReadonly() {
    return !!this.readonlyMessages;
  }

  public createChapterLink(chapter: Partial<IChapter>): ISearchResult {
    const course = this.nav.currentCourse;
    if (!course) {
      return null;
    }
    const column = course.columns.find((column) => column.id === chapter.columnId);
    if (!column) {
      return null;
    }
    return {
      urlPath: `/courses/course/${course.url_slug}/${column.url_slug}/${chapter.url_slug}`,
      chapter: {
        id: chapter._id,
        slug: chapter.url_slug,
        title: chapter.title,
      },
      column: {
        id: column.id,
        slug: column.url_slug,
        title: column.title,
        type: column.chapter_style,
      },
      course: {
        id: course._id,
        slug: course.url_slug,
        title: course.title,
      },
      preview: chapter.title,
      score: 1,
    };
  }

  public openLink(link: ISearchResult) {
    this.nav.goToChapter({
      url_slug: link.chapter.slug,
    } as IChapter, {
      url_slug: link.column.slug,
    } as IColumn);
  }

  public async submit(event: Event) {
    event.preventDefault();

    const input = this.userInput.trim();
    this.userInput = '';

    if (!input) {
      this.autoGrow();
      return;
    }

    this.isReceivingMessage.next(true);

    const userMessage: AiMessage = {
      ai: false,
      text: new BehaviorSubject<string>(input.replace(/\n/g, '<br>')),
    };
    this.messages.next([...this.messages.getValue(), userMessage]);

    const nextMessage: AiMessage = {
      ai: true,
      text: new BehaviorSubject<string>(''),
      isLoading: new BehaviorSubject(true),
      chapterLinks: new BehaviorSubject<ISearchResult[]>([]),
    };
    const receivedTokens = [];

    await this.client.streamResponse(createChatbotUrl('chat', this.sessionId), {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        question: input,
        targetId: this.chatTargetId,
        targetType: this.chatTargetType,
      } as ChatRequest),
    }, {
      started: () => {
        this.autoGrow();
        this.messages.next([...this.messages.getValue(), nextMessage]);
      },
      error: async (error, response) => {
        console.error('Could not get ai response:', error.message);
        if (response.status === HttpStatusCode.Forbidden) {
          try {
            const errorPayload = await response.json();
            if (errorPayload?.reason === 'MESSAGE_LIMIT_REACHED') {
              this.messageLimitReached = true;
              this.saveSession();
            }
          } catch (e) {
            console.error(e);
          }
        } else {
          this.messages.next([...this.messages.getValue(), {
            text: new BehaviorSubject<string>('Es kam zu einem Fehler beim Bearbeiten der Anfrage. Sollte der ' +
              'Fehler weiterhin auftreten, wende dich an einen Tutor oder eine Tutorin.'),
            ai: true,
            isLoading: new BehaviorSubject(false),
            error: true,
          }]);
        }
        this.isReceivingMessage.next(false);
      },
      next: (line) => {
        if (!line) {
          return;
        }

        const content = line.replace(/^[\da-zA-Z]+:/, '');
        let json = null;
        try {
          json = JSON.parse(content);
        } catch (e) {
          console.error('Could not parse json for line', line, ':', e);
        }

        const isSystemMessage = line.match(/^2:/) && json;
        const isMetaDataMessage = !!line.match(/^d:/);
        const isContentMessage = !!line.match(/^0:/);
        if (isSystemMessage) {
          const systemMessage: ChatSystemMessage = JSON.parse(json[0]);
          if (systemMessage.sessionId !== undefined) {
            this.sessionId = systemMessage.sessionId;
          }
          if (systemMessage.limitReached !== undefined) {
            this.messageLimitReached = systemMessage.limitReached;
          }
          if (systemMessage.chapterLinks !== undefined) {
            nextMessage.chapterLinks.next(
              systemMessage.chapterLinks.map((link) => this.createChapterLink(link)),
            );
          }
        } else if (isMetaDataMessage) {
          // currently ignored
        } else if (isContentMessage) {
          const rawLineContent = (json || content);
          receivedTokens.push(rawLineContent);
          const messageContent = receivedTokens.join('');
          nextMessage.text.next(messageContent);
        }
      },
      done: () => {
        nextMessage.isLoading.next(false);
        this.isReceivingMessage.next(false);
        this.showChatControls$.next(true);
        this.saveSession();
      },
    });
  }

  public autoGrow(): void {
    const element = this.inputElement.nativeElement as HTMLTextAreaElement;
    element.style.height = '5px';
    element.style.height = element.scrollHeight + 6 + 'px';
  }

  public feedback(type: Feedback) {
    if (type !== this.feedback$.getValue()) {
      this.feedback$.next(type);
      this.bag.add(
        this.client.post(createChatbotUrl('chat', this.sessionId, 'feedback'),
          {
            rating: type === 'positive' ? 1 : 0,
          }).subscribe(() => {
            this.saveSession();
        }),
      );
    }
  }

  public reset() {
    sessionStorage.removeItem(this.storageKey);
    this.sessionId = null;
    this.messageLimitReached = false;
    this.messages.next([]);
    this.isReceivingMessage.next(false);
    this.showChatControls$.next(false);
    this.feedback$.next(null);
  }

  public ngOnInit() {
    const storedSessionRaw= sessionStorage.getItem(this.storageKey);
    if (storedSessionRaw) {
      try {
        const storedSession = JSON.parse(storedSessionRaw);
        this.sessionId = storedSession.sessionId;
        this.messages.next(storedSession.messages.map((message) => {
          return {
            text: new BehaviorSubject(message.text),
            ai: message.ai,
            error: message.error,
            isLoading: new BehaviorSubject(false),
            chapterLinks: new BehaviorSubject(message.chapterLinks),
          } as AiMessage;
        }));
        this.showChatControls$.next(storedSession.showChatControls);
        this.feedback$.next(storedSession.feedback);
        this.messageLimitReached = storedSession.messageLimitReached;
      } catch (e) {
        console.error('could not restore session', e);
        this.reset();
      }
    }

    if (this.readonlyMessages) {
      this.messages.next(this.readonlyMessages);
    }
  }

  private saveSession() {
    sessionStorage.setItem(this.storageKey, JSON.stringify({
      sessionId: this.sessionId,
      showChatControls: this.showChatControls$.getValue(),
      feedback: this.feedback$.getValue(),
      messageLimitReached: this.messageLimitReached,
      messages: this.messages.getValue().map((message) => {
        return {
          text: message.text.getValue(),
          ai: message.ai,
          error: message.error,
          chapterLinks: message.chapterLinks?.getValue() || [],
        };
      }),
    }));
  }

  private get storageKey() {
    return `ai_chat_${this.chatTargetType}_${this.chatTargetId}`;
  }

  private get chatTargetType(): ChatTarget {
    if (this.section) {
      return ChatTarget.Section;
    }
    if (this.chapter) {
      return ChatTarget.Chapter;
    }
    return null;
  }

  private get chatTargetId(): string {
    switch (this.chatTargetType) {
      case ChatTarget.Chapter:
        return this.chapter._id;
      case ChatTarget.Section:
        return this.section._id;
      default:
        return null;
    }
  }

  public formatAiMessage(message: string) {
    return formatAiMessage(message);
  }
}
