import { Component } from '@angular/core';

@Component({
  selector: 'app-element-loader',
  template: `
    <i
      class="fa fa-spinner animated loading"
      aria-hidden="true"
    ></i>
  `,
  styleUrls: ['./element-loader.component.scss'],
})
export class ElementLoaderComponent {}
