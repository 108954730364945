import { IUploadResult } from './types';
import { BehaviorSubject, Subject } from 'rxjs';
import { IMediaRecorder, RecordStatus } from './types';
import { HttpService } from '@core/http';
import { createMediaUrl } from '@utils/index';

const RECEIVE_CHUNK_INTERVAL = 100;
const MIME_TYPE = 'audio/mp4';

const extractFormat = (mimeType) => {
  const match = mimeType.match(/\/([^;]+)/);
  return match ? match[1] : null;
};

export class AudioCapturer implements IMediaRecorder {
  public uploadResult$ = new Subject<IUploadResult | undefined>();
  public recordStatus$ = new BehaviorSubject<RecordStatus>('none');
  public error$ = new Subject<Error | null>();
  public mediaRecorder: MediaRecorder = null;

  private audioStream: MediaStream | null = null;

  constructor(private httpClient$?: HttpService<IUploadResult>) {}

  public get isAudioInProgress() {
    return (
      this.recordStatus$.getValue() === 'inprogress' &&
      this.audioStream !== null
    );
  }

  public async startRecord() {
    try {
      this.audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      const options = { mimeType: MIME_TYPE };

      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.error('Codec or MIME type not supported');
        throw new Error('Unsupported MIME type');
      }

      const mediaRecorder = new MediaRecorder(this.audioStream, options);
      const chunks: BlobPart[] = [];

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data);
        }
      };

      mediaRecorder.onstop = () => {
        // We need to wait for RECEIVE_CHUNK_INTERVAL to be sure all media data is available
        setTimeout(() => {
          const audioBlob = new Blob(chunks, { type: MIME_TYPE });
          this.handleReceivedMediaBlob(audioBlob);
        }, RECEIVE_CHUNK_INTERVAL);
      };
      this.recordStatus$.next('inprogress');
      mediaRecorder.start(RECEIVE_CHUNK_INTERVAL);

      return this.uploadResult$;
    } catch (error) {
      this.setError(new Error(error));
      this.recordStatus$.next('error');
    }
  }

  public stopRecord() {
    this.stopTracks(this.audioStream);
    this.audioStream = null;
  }

  public async handleReceivedMediaBlob(blob: Blob | undefined) {
    if (blob?.size > 0) {
      try {
        const fileExtension = extractFormat(blob.type);
        if (!fileExtension) {
          throw new Error('Mime type not exists');
        }
        const formData = new FormData();
        formData.append('file', blob, `audio.${fileExtension}`);

        const result = await this.makeRequest(formData);

        this.uploadResult$.next(result);
        this.recordStatus$.next('success');
      } catch (e) {
        this.setError(new Error(e.message || 'upload error'));
      }
    } else {
      this.setError(new Error('Received blob is empty'));
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  public getMedia(_id: string): Promise<any> {
    return Promise.resolve({} as IUploadResult);
  }

  private stopTracks(stream: MediaStream) {
    stream.getTracks().forEach((track) => track.stop());
  }

  private setError(error: Error) {
    this.recordStatus$.next('error');
    this.error$.next(error);
    this.stopRecord();
  }

  private makeRequest(formData: FormData) {
    return new Promise<IUploadResult>((res, rej) => {
      this.httpClient$
        .post<IUploadResult>(createMediaUrl('audios', 'upload'), formData)
        .subscribe(
          (result) => {
            res(result);
          },
          (e) => {
            rej(e);
          },
        );
    });
  }
}
