import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { RenderMathPipeModule } from 'app/shared/pipes/renderMath';
import { ModalsModule } from '@components/modals/modals.module';
import { RemoveWhitespacesPipeModule } from 'app/shared/pipes/removeWhiteSpaces';
import { SidebarService } from './sidebar.service';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
  providers: [SidebarService],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ProgressBarModule,
    RenderMathPipeModule,
    ModalsModule.forChild(),
    RemoveWhitespacesPipeModule,
  ],
})
export class SidebarModule {}
