import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, share } from 'rxjs/operators';
import IOrder, { IOrderPayload, ISummaryRequest } from '@models/order';
import { createProfileUrl } from '@utils/urlFactory';
import { HttpService } from '@core/http';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private client: HttpService<IOrder>) {}

  public checkoutState = (body: ISummaryRequest): Observable<unknown> => {
    return this.client.post<unknown>(createProfileUrl('order', 'summary'), body);
  };

  public orderByBilling = (
    orderRequest: IOrderPayload,
  ): Observable<IOrder> => {
    return this.client
      .post<IOrder>(createProfileUrl('order', 'billing'), orderRequest)
      .pipe(
        share(),
        catchError((error) => {
          return throwError(error);
        }),
      );
  };

  public getOrdersExportForDateRange = (
    dateStart: string,
    dateEnd: string,
  ): Observable<IOrder[]> => {
    return (
      this.client
        .get<IOrder[]>(
          createProfileUrl('order', 'list', 'csv', dateStart, dateEnd),
        )
        .pipe(
          share(),
          catchError((error) => {
            return throwError(error);
          }),
        )
    );
  };

  public getParticipantsStatistic = (): Observable<IOrder[]> => {
    return (
      this.client
        .get<IOrder[]>(
          createProfileUrl('order', 'course', 'participants', 'csv'),
        )
        .pipe(
          share(),
          catchError((error) => {
            return throwError(error);
          }),
        )
    );
  };

  public getOrdersStatistic = (startDate, endDate, courseSlug): Observable<IOrder[]> => {
    return (
      this.client
        .get<IOrder[]>(
          createProfileUrl('order', 'course', 'orders', 'csv', startDate, endDate, courseSlug),
        )
        .pipe(
          share(),
          catchError((error) => {
            return throwError(error);
          }),
        )
    );
  };

  public confirmPaypalTransaction = (
    transactionId: string,
  ): Observable<IOrder> => {
    return this.client
      .get<IOrder>(
        createProfileUrl('order', 'paypal', 'capture', transactionId),
      )
      .pipe(share());
  };

  public createPaypalTransaction = (
    payload: IOrderPayload,
  ): Promise<IOrder> => {
    return new Promise((resolve, reject) => {
      this.client
        .post<IOrder>(createProfileUrl('order', 'paypal'), payload)
        .subscribe(
          (t) => {
            resolve(t);
          },
          (e) => reject(e),
        );
    });
  };
}
