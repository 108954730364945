/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '@core/http';
import { createContentUrl } from '@utils/urlFactory';
import TeamMemberResponse from '@models/team-member';
import { ICourseDetails, ICourseMessageTemplate } from '@models/course';
import { IUniversityDetails } from '@models/university';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(
    private httpClient: HttpService<any>,
  ) {
  }

  public getTeamMembers(): Observable<TeamMemberResponse[]> {
    return this.httpClient.get(createContentUrl('content', 'team-members'));
  }

  public getCourseMessageTemplate(urlSlug: string): Observable<ICourseMessageTemplate> {
    return this.httpClient.get(createContentUrl('content', 'courses', 'message-template', urlSlug));
  }

  public getCourseDetails(urlSlug: string): Observable<ICourseDetails> {
    return this.httpClient.get(createContentUrl('content', 'courses', urlSlug));
  }

  public getCourseDetailsAsAny(urlSlug: string): Observable<any> {
    return this.getCourseDetails(urlSlug) as Observable<any>;
  }

  public getUniversity(urlSlug: string): Observable<IUniversityDetails> {
    return this.httpClient.get(createContentUrl('content', 'universities', urlSlug));
  }
}
