// src/app/auth/auth-guard.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard  {
  constructor(public auth: LoginService, public router: Router) {}
  canActivate(): boolean {
    if (this.auth.loggedInValue) {
      this.router.navigate(['mycourses']);
      return false;
    }
    return true;
  }
}
