import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input, OnInit,
  ViewEncapsulation,
} from '@angular/core';
import BasicRxComponent from '@components/BasicRxComponent';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-container-loader',
  template: `
    <div class="loader-wrapper" [ngClass]="{ hidden: (loading$ | async) === false }">
      <app-element-loader></app-element-loader>
    </div>
    <ng-content></ng-content>
  `,
  styleUrl: './container-loader.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerLoaderComponent extends BasicRxComponent implements OnInit {
  @Input() loading$: Observable<boolean> = of(false);

  @HostBinding('class.loading') loadingClass: boolean;

  ngOnInit() {
    this.bag.add(this.loading$.subscribe((v) => (this.loadingClass = v)));
  }
}
