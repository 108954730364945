import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { LoginService } from '@core/auth/login.service';
import { environment } from '@environments/environment';
import { BrowserService } from '@services/browser.service';
import { TEST_IDS } from './topbar.constants';
import { IAuthProfile } from '@core/auth';

@Component({
  selector: 'topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarComponent implements OnInit {
  public open: boolean;

  private lastScrollTop = 0;
  public TEST_IDS: typeof TEST_IDS = TEST_IDS;
  public hideNavBar = new BehaviorSubject<boolean>(false);
  public opaqueNavBar = new BehaviorSubject<boolean>(false);
  public environment = environment;
  public baseURL = environment.baseUrl;

  public isLoggedIn: Observable<boolean>;

  public profileInfo: Observable<IAuthProfile>;
  public isAdmin: Observable<boolean>;

  public showUser = false;

  public isSafari: boolean;

  constructor(
    public loginService: LoginService,
    private browserService: BrowserService,
  ) {}

  ngOnInit(): void {
    this.open = false;
    this.isSafari = this.browserService.isSafari();
    this.isLoggedIn = this.loginService.isLoggedIn;
    this.profileInfo = this.loginService.profileInfo;
  }

  public toggleNav(value = null): void {
    this.open = value === null ? !this.open : !!value;
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const currentScrollTop = document.scrollingElement.scrollTop;
    this.hideNavBar.next(
      currentScrollTop > 0 && this.lastScrollTop < currentScrollTop,
    );
    this.opaqueNavBar.next(currentScrollTop > 20);
    this.lastScrollTop = currentScrollTop;
  }

  public login(): void {
    this.loginService.login();
  }

  public register(): void {
    this.loginService.login(null, null, true);
  }

  public logout(): void {
    this.loginService.logout();
  }

  public logoUrl(): string {
    return './assets/images/logo-short-3-blue.png';
    // return this.isStudyprime
    //   ? "./assets/studyprime/svg/logo.svg"
    //   : ;
  }

  public logoUrlNaked(): null | string {
    return './assets/images/logo-2-white.png';
  }

}
