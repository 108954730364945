/**
 * Created by huck on 15.06.18
 */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges, ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import IComment from '@models/comment';
import { IChapter } from '@models/chapter';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import ISection from '@models/section';
import BasicRxComponent from '@components/BasicRxComponent';
import { CommentsService } from '@services/comments.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginService } from '@core/auth/login.service';
import { ModalsService } from '@components/modals/modals.service';
import { PermissionService } from '@core/auth/permission.service';
import { IDialog } from '@components/modals/modal.types';
import { tap } from 'rxjs/operators';
import { ActivityService } from '@services/activity.service';

@Component({
  selector: 'comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsComponent
  extends BasicRxComponent
  implements OnInit, OnDestroy, OnChanges
{
  content: SafeHtml;
  @Input() public editMode = false;
  @Input() public section: ISection;
  @Input() public chapterId: string;
  @Input() public chapter: IChapter;
  @Input() public adminMode = false;
  @Input() public style = '';
  @Input() public requireVideoBeforeCommenting = false;
  @Output() public addComment = new EventEmitter<IComment>();

  @ViewChild('commentsErrorModal') public commentsErrorModal: IDialog;
  @ViewChild('commentsSuccessModal') private commentsSuccessModal: IDialog;

  public newCommentForm: UntypedFormGroup;
  public expanded = false;
  public isAnonymous: BehaviorSubject<boolean> = this.loginService.isAnonymous;
  public submitted = false;
  public some: string;
  public comments = new BehaviorSubject<IComment[] | null>([]);
  public errorText = '';
  public isCommentsHidden$ = new BehaviorSubject<boolean>(false);
  public isPostingComment$ = new BehaviorSubject(false);

  constructor(
    private commentService: CommentsService,
    private loginService: LoginService,
    public modalsService: ModalsService,
    public permissionService: PermissionService,
    public activityService: ActivityService,
  ) {
    super();
  }

  @HostBinding('class.comments_admin') get isAdminClassActive() {
    return this.adminMode;
  }

  public onToggle(isOpen: boolean) {
    if (isOpen) {
      this.loadComments();
    }
  }

  public get editCommentsAllowed$() {
    return this.permissionService.isAdminRole$;
  }

  public postComment(): Observable<IComment> {
    if (!this.newCommentForm.valid) {
      this.onError('Bitte prüfe dein Kommentar auf Fehler !');
      return;
    }
    this.isPostingComment$.next(true);
    return this.commentService.postComment(
      this.chapterId,
      this.section ? this.section._id : null,
      this.newCommentForm.value as IComment,
    ).pipe(
      tap(() => {
        this.isPostingComment$.next(false);
      }),
    );
  }

  public get f(): { [key: string]: AbstractControl } {
    return this.newCommentForm.controls;
  }

  public submit(event: Event): void {
    this.submitted = true;
    if (this.newCommentForm.valid) {
      this.isPostingComment$.next(true);
      this.postComment().subscribe(
        () => {
          this.modalsService.open(this.commentsSuccessModal);
          this.newCommentForm.reset();
          const textarea = jQuery(event.target).siblings('textarea');
          if (textarea.length > 0) {
            this.autoGrow(textarea[0]);
          }
          this.loadComments(true);
          this.isPostingComment$.next(false);
        },
        (error) => {
          this.onError('Fehler beim hochladen deiner Frage: ' + error.message);
          this.isPostingComment$.next(false);
        },
      );
    } else {
      this.onError(
        'Leider ist ein Fehler aufgetreten - bitte überprüfe deine Angaben.',
      );
    }
  }

  public onSuccessReply() {
    this.modalsService.open(this.commentsSuccessModal);
  }

  public onError(errorText: string) {
    this.errorText = errorText;
    this.modalsService.open(this.commentsErrorModal);
  }

  public autoGrow(element): void {
    element.style.height = '5px';
    element.style.height = element.scrollHeight + 6 + 'px';
  }

  public onInputFocus() {
    this.activityService.push({
      type: 'focus',
      value: 'question-input',
      chapterId: this.chapterId || this.chapter?._id,
      sessionId: this.section?._id,
    });
  }

  public onInputBlur() {
    this.activityService.push({
      type: 'blur',
      value: 'question-input',
      chapterId: this.chapterId || this.chapter?._id,
      sessionId: this.section?._id,
    });
  }

  public ngOnInit(): void {
    this.overWrightStatsCount();
    this.initForm();

    this.loadComments();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.resetCommentAndCloseIfNeeded(changes);
  }

  private loadComments(getFreshData?: boolean): void {
    this.bag.add(
      this.commentService
        .getComments(
          this.chapterId,
          this.section ? this.section._id : null,
          getFreshData,
        )
        .subscribe((comments) => {
          this.comments.next([...comments]);
        }),
    );
  }

  private resetCommentAndCloseIfNeeded(changes: SimpleChanges) {
    const { chapterId } = changes;
    if (!chapterId) {
      return;
    }
    const { previousValue, currentValue } = chapterId;

    if (previousValue !== currentValue) {
      this.comments.next([]);
    }
  }

  private initForm() {
    this.newCommentForm = new UntypedFormGroup({
      text: new UntypedFormControl('', [
        Validators.minLength(5),
        Validators.required,
      ]),
    });
  }

  private overWrightStatsCount() {
    if (this.section) {
      this.section.stats.commentCount = this.section.stats.commentCount || 0;
    } else if (this.chapter) {
      this.chapter.stats.commentCount = this.chapter.stats.commentCount || 0;
    }
  }
}
