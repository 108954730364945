import { Injectable } from '@angular/core';
import { IColumn, ICourse } from '@models/course';
import { BehaviorSubject } from 'rxjs';

import { ISidebarConfig } from './sidebar.types';
2;

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  public context: BehaviorSubject<ISidebarConfig> =
    new BehaviorSubject<ISidebarConfig>({ show: false });

  public next(context: ISidebarConfig): void {
    this.context.next(context);
  }

  constructor() {}

  showSideBarWithData({
    course,
    column,
  }: {
    course: ICourse;
    column?: IColumn;
  }) {
    this.next({ show: true, course, column });
  }
}
