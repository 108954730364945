import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  AfterViewInit,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { VideoPlayerService } from '../../video-player.service';

import Player from '@vimeo/player';
import {
  IVideoPlayer,
  IVideoPlayerControllable,
  IVideoSource,
  PlayerStateChangeAction,
  PLAYER_STATE_TYPES,
} from '../../types';

interface IPlayerConfig {
  controls?: boolean;
}

const DEFAULT_CONFIG = {
  controls: true,
  responsive: true,
  maxwidth: 800,
};

@Component({
  selector: 'app-video-player',
  template: '<div [id]="id" #container class="videoContainer"></div>',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent
  implements
    AfterViewInit,
    OnInit,
    OnDestroy,
    IVideoPlayer,
    IVideoPlayerControllable
{
  @Input() public videoSource: IVideoSource;
  @Input() private config: IPlayerConfig = DEFAULT_CONFIG;

  @Output() onPlayerStateChange: EventEmitter<
    PlayerStateChangeAction<Player | undefined>
  > = new EventEmitter();

  @ViewChild('container') private containerRef: ElementRef<HTMLDivElement>;

  public id: string;
  public isPlaying = false;

  constructor(
    private sanitizer: DomSanitizer,
    private videoPlayerService: VideoPlayerService,
  ) {}

  private playerInstance: Player | undefined = undefined;

  public transform(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public pause() {
    this.playerInstance.pause();
  }

  public play() {
    this.playerInstance.play();
  }

  public stop() {
    this.playerInstance.unload();
  }

  ngAfterViewInit(): void {
    this.initPlayer();
    this.subscribeToInstanceEvents();
  }

  ngOnInit() {
    this.id = this.getUniqHTMLId();
  }

  ngOnDestroy() {
    this.videoPlayerService.removeInstance(this.id);
  }

  private get playerConfig() {
    return {
      ...DEFAULT_CONFIG,
      ...this.config,
      url: this.videoSource.url,
    };
  }

  private get container() {
    return this.containerRef.nativeElement;
  }

  private subscribeToInstanceEvents() {
    this.playerInstance.on('play', async (event: Player | undefined) => {
      await this.videoPlayerService.onPlayHandler(this.id);
      this.isPlaying = true;

      this.onPlayerStateChange.emit(
        new PlayerStateChangeAction(PLAYER_STATE_TYPES.PLAYING, event),
      );
    });
    this.playerInstance.on('pause', async (event: Player | undefined) => {
      this.isPlaying = false;
      this.onPlayerStateChange.emit(
        new PlayerStateChangeAction(PLAYER_STATE_TYPES.PAUSE, event),
      );
    });
  }

  private initPlayer() {
    try {
      this.playerInstance = new Player(this.container, this.playerConfig);
      this.onPlayerStateChange.emit(
        new PlayerStateChangeAction(PLAYER_STATE_TYPES.INIT, this.playerInstance),
      );
      this.videoPlayerService.addInstance(this.id, this.playerInstance);
    } catch(e) {
      console.error(e);
    }

  }

  private getUniqHTMLId() {
    return `video-${this.videoSource.url}-${Date.now()}`;
  }
}
