import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { pagesNames } from '../../../modules/public-access-modules/shared/constants/pages-names';
import { TranslationService } from '@services/translation.service';
import IFooterContent from '../../../modules/public-access-modules/shared/models/footer-content.model';

@Component({
  selector: 'footer-component',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FooterComponent implements OnInit {
  public year = 2020;
  public content: IFooterContent = this.translationService.getPageContent(
    pagesNames.footer,
  );

  constructor(private translationService: TranslationService) {}

  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }
}
