import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { Preference } from '@models/preference';
import { DisposeBag } from '@utils/DisposeBag';

import { Auth0Error, WebAuth } from 'auth0-js';
import { environment } from '@environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { createProfileUrl, generateUUID } from '@utils/index';
import { LocalStorageService } from '@services/local-storage';
import { IAuthProfile, IAuthService, ILoginState } from './types';
import {
  STORAGE_KEYS,
  ONE_DAY_IN_SECONDS,
  ROLES_URL,
  PROFILE_INFO_URL, COURSE_ACCESS, TOKEN_VERSION,
} from './auth.constants';
import { BrowserService } from '@services/browser.service';
import { WindowRefService } from '@services/window-ref.service';
import { ModalsService } from '@components/modals/modals.service';

interface ISession extends auth0.Auth0DecodedHash {
  anonymousId?: string;
  expiresAt?: number;
}
export const getAnonymousLink = (): string => {
  return createProfileUrl('get-anonymous-token');
};

@Injectable({
  providedIn: 'root',
})
export class LoginService implements IAuthService {
  private auth0: auth0.WebAuth = new WebAuth({
    ...environment.auth0,
    audience: '',
  });

  private _profileInfo$ = new BehaviorSubject<IAuthProfile>(null);
  private _isAdmin$: Observable<boolean>;
  private _isAnonymous$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _isLoggedIn = new BehaviorSubject<boolean>(false);
  private _bag = new DisposeBag();
  private _isReady = new BehaviorSubject<boolean>(false);
  public refreshInProgress = new BehaviorSubject<boolean>(false);
  private jwt: JwtHelperService;

  constructor(
    private router: Router,
    private browser: BrowserService,
    private client: HttpClient,
    private storageService: LocalStorageService,
    private windowRefService: WindowRefService,
    private modalService: ModalsService,
  ) {
    this.jwt = new JwtHelperService();
    this.init().finally(() => {
      this._isReady.next(true);
    });
  }

  public hasPurchasedCourse(courseId: string): boolean {
    return this.currentIsAdmin || this.purchasedCourses.indexOf(courseId) >= 0;
  }

  public storePreference(preference: Preference, value: string): void {
    this.storageService.setItem(JSON.stringify(preference), value);
  }

  public storePreferenceForImmediateKey(
    preference: string,
    value: string,
  ): void {
    this.storageService.setItem(preference, value);
  }

  public retrievePreferenceByImmediateKey(preference: string): string {
    return this.storageService.getItem(preference);
  }

  public retrievePreference(preference: Preference): string {
    return this.storageService.getItem(preference.toString());
  }

  public reLoginRestoringState(): Promise<void> {
    const nonce = generateUUID();
    const state = this.loginstate;
    this.logout(false);

    return this.login(nonce, state);
  }

  public loginBookingCourse = (
    course: string = null,
    register = false,
  ): void => {
    let state: ILoginState = null;

    if (course) {
      state = {
        bookmarkCourse: course,
      };
    }
    this.storageService.setItem('redirectUrl', '/order/' + course);

    const nonce = generateUUID();
    this.login(nonce, state, register);
  };

  public login(
    nonce: string = null,
    state: ILoginState = null,
    register = false,
  ): Promise<void> {
    const isAnonymous = this._isAnonymous$.getValue();
    const options = {};

    if (register) {
      options['mode'] = 'signUp';
      options['screen_hint'] = 'signup';
      options['initialScreen'] = 'signUp';
    }

    this.removeServerLogoutForSafari();

    if (isAnonymous) {
      this.auth0.authorize(options);
      return;
    }

    this.clearNonceIfNeeded();
    if (this.isTokenValidByTime) {
      return;
    }

    if (nonce) {
      this.storePreference(Preference.nonce, nonce);
      if (state) {
        this.setLoginstate(nonce, state);
      }
    }

    if (nonce) {
      options['state'] = nonce;
    }

    this.auth0.authorize();
    return;
  }

  public logout(redirect = true): void {
    this.storageService.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
    this.storageService.removeItem(STORAGE_KEYS.ID_TOKEN);
    this.storageService.removeItem(STORAGE_KEYS.IS_LOGGED_IN);
    this.storageService.removeItem(STORAGE_KEYS.EXPIRE);
    this.storageService.removeItem(STORAGE_KEYS.ANONYMOUS);

    this.setLoggedOutIfSafari();

    if (redirect) {
      this.auth0.logout({ returnTo: environment.logoutUrl });
    }
  }

  public async waitForServiceToBeReady(): Promise<boolean> {
    return new Promise((resolve) => {
      this._isReady.subscribe((isReady) => {
        resolve(isReady);
      });
    });
  }

  public async getAnonymous(): Promise<void> {
    const url = getAnonymousLink();

    const response = await this.client
      .get<{ token: string }>(url, {
        responseType: 'json',
      })
      .toPromise();

    const token = response.token;
    const decodedToken = this.jwt.decodeToken(token);

    this.setSession({
      accessToken: token,
      idToken: token,
      anonymousId: decodedToken.anonymousId,
      expiresAt: this.getExpiresIn(ONE_DAY_IN_SECONDS),
    });
  }

  public tryToRefreshToken(): Observable<boolean> {
    if (this.refreshInProgress.getValue() || this.isLoggedOutSafari) {
      return of();
    }
    this.refreshInProgress.next(true);
    return from(
      new Promise<boolean>((resolve, reject) => {
        if (!this.validateByJWTTokens()) {
          resolve(false);
        }

        this.refreshToken()
          .then((result) => {
            resolve(result);
          })
          .catch((e) => {
            console.error(e);
            // This error is thrown by auth0 lib,
            // when after refresh it's not found a token
            if (e && e.code === 'login_required') {
              this.logout(false);
              this.auth0.authorize();
            } else {
              reject(e);
            }
          })
          .finally(() => {
            this.removeServerLogoutForSafari();
            this.refreshInProgress.next(false);
          });
      }),
    );
  }

  public get loginstate(): ILoginState {
    if (!this.nonce) {
      return null;
    }
    const stateValue = this.storageService.getItem(this.nonce);
    if (!stateValue) {
      return null;
    }
    return JSON.parse(stateValue) as ILoginState;
  }

  public get idToken(): string | null {
    return this.storageService.getItem(STORAGE_KEYS.ID_TOKEN);
  }

  public get currentIsAdmin(): boolean {
    const profileInfo = this._profileInfo$.value;
    if (!profileInfo || !profileInfo[ROLES_URL]) {
      return false;
    }
    return profileInfo[ROLES_URL].some((role) => role.startsWith('admin'));
  }

  public get isReady(): BehaviorSubject<boolean> {
    return this._isReady;
  }

  public get isAdmin(): Observable<boolean> {
    return this._isAdmin$;
  }

  public get isLoggedIn(): BehaviorSubject<boolean> {
    return this._isLoggedIn;
  }

  public get isAnonymous(): BehaviorSubject<boolean> {
    return this._isAnonymous$;
  }

  public get profileInfo(): BehaviorSubject<IAuthProfile> {
    return this._profileInfo$;
  }

  public get loggedInValue(): boolean {
    return this._isLoggedIn.getValue();
  }

  public get purchasedCourses(): string[] {
    const profile = this._profileInfo$.getValue();
    return (profile && profile[PROFILE_INFO_URL]) || [];
  }

  public handleSessionChangeIfNecessary() {
    const profile = this.profileInfo.getValue() as IAuthProfile;
    let tokenPayload = null;
    try {
      tokenPayload = this.jwt.decodeToken(this.idToken);
    } catch (e) {
      console.error(e);
      this.logout(true);
    }
    if (profile && tokenPayload) {
      if (profile.sub !== tokenPayload.sub) {
        this.modalService.openGlobalModal(this.modalService.MODAL_IDS.SESSION_EXPIRED);
        throw new Error(
          `Session expired due to profile and token user id mismatch: ${profile.sub} != ${tokenPayload.sub}`,
        );
      } else if (profile[TOKEN_VERSION] !== tokenPayload[TOKEN_VERSION]) {
        profile[TOKEN_VERSION] = tokenPayload[TOKEN_VERSION];
        profile[PROFILE_INFO_URL] = tokenPayload[PROFILE_INFO_URL];
        profile[ROLES_URL] = tokenPayload[ROLES_URL];
        profile[COURSE_ACCESS] = tokenPayload[COURSE_ACCESS];
        this.profileInfo.next(profile);
      }
    }
  }

  public get courseAccess() {
    const profile = this._profileInfo$.getValue();
    return (profile && profile[COURSE_ACCESS]) || [];
  }

  public get tokenVersion(): string {
    const profile = this._profileInfo$.getValue();
    return (profile && profile[TOKEN_VERSION]) || '';
  }

  private get isLoggedOutSafari() {
    return (
      this.storageService.getItem(STORAGE_KEYS.SAFARI_LOGGED_OUT) === 'true'
    ) && this.browser.isSafari();
  }

  private async init() {
    this.mapAdminStream();
    this.mapProfileStream();
    return this.tryToParseAuthParams()
      .then((successfullyReadFromURL) => {
        /**
         * It means there was a redirect from auth0 login page with new tokens in url, and `tryToParseAuthParams`
         * successfully set session data and we don't need to read tokens from storage,
         * in other case it should read from local storage and verify it
         */
        if (!successfullyReadFromURL) {
          return this.getTokens();
        }
        return;
      })
      .catch((e) => {
        console.error(e);
      });
  }

  private async tryToParseAuthParams(): Promise<boolean> {
    return new Promise((res, rej) => {
      this.auth0.parseHash((err, authResult: auth0.Auth0DecodedHash) => {
        if (!err && !authResult) {
          res(false);
        }

        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);

          const customRedirectLink =
            this.storageService.getItem('redirectUrl') || '/mycourses';

          this.storageService.removeItem('redirectUrl');

          this.router.navigate([customRedirectLink]);
          const window = this.windowRefService.nativeWindow;

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'pageview_login',
          });

          res(true);
        } else if (err) {
          this.router.navigate(['/home']);
          console.error(err);
          rej(err);
        }
      });
    });
  }

  private setLoginstate(nonce: string, state: ILoginState) {
    this.storageService.setItem(nonce, JSON.stringify(state));
  }

  private clearNonceIfNeeded() {
    if (this.nonce) {
      this.storageService.removeItem(this.nonce);
      this.storageService.removeItem(Preference.nonce);
    }
  }

  private setSession({
    accessToken,
    idToken,
    anonymousId,
    expiresAt,
  }: ISession): void {
    let isLoggedIn = true;
    const tokenPayload = this.jwt.decodeToken(idToken);
    /**For anonimous we provided it directly, not at token */
    const _expiresAt = (expiresAt || tokenPayload.exp) * 1000;

    this.storageService.setItem(STORAGE_KEYS.ACCESS_TOKEN, accessToken);
    this.storageService.setItem(STORAGE_KEYS.ID_TOKEN, idToken);
    this.storageService.setItem(STORAGE_KEYS.EXPIRE, _expiresAt.toString());

    if (anonymousId) {
      this.storageService.setItem(STORAGE_KEYS.ANONYMOUS, anonymousId);
      isLoggedIn = false;
    }
    this.afterSessionSet(tokenPayload as IAuthProfile, isLoggedIn);
  }

  private afterSessionSet(profileInfo: IAuthProfile, isLoggedIn: boolean) {
    this._profileInfo$.next(profileInfo);
    this._isLoggedIn.next(isLoggedIn);
    this.removeServerLogoutForSafari();
  }

  private async getTokens(): Promise<void> {
    const idToken = this.storageService.getItem(STORAGE_KEYS.ID_TOKEN);
    const anonimus = this.storageService.getItem(STORAGE_KEYS.ANONYMOUS);

    if (!idToken) {
      await this.getAnonymous();
      return;
    }

    if (!this.isTokenValidByTime) {
      if (anonimus) {
        await this.getAnonymous();
        return;
      } else {
        const isSuccess = await this.tryToRefreshToken().toPromise();
        if (!isSuccess) {
          this.logout(true);
        }

        return;
      }
    }
    if (anonimus) {
      this.storageService.removeItem(STORAGE_KEYS.ANONYMOUS);
    }
    const decodedToken = this.jwt.decodeToken(idToken);
    this.afterSessionSet(decodedToken as IAuthProfile, true); // always logged in at this point
  }

  private mapAdminStream() {
    const mapFn = (info: IAuthProfile) => {
      if (info) {
        const roles = info[ROLES_URL];
        if (roles) {
          return roles.some((role) => typeof role === 'string' && role.startsWith('admin'));
        }
      }
      return false;
    };
    this._isAdmin$ = this._profileInfo$.pipe(map(mapFn));
  }

  private mapProfileStream() {
    const mapFn = (info: IAuthProfile) => {
      if (info) {
        this._isAnonymous$.next(!!info.anonymousId);
      } else {
        this._isAnonymous$.next(false);
      }
    };

    this._bag.add(this._profileInfo$.pipe(map(mapFn)).subscribe());
  }



  private validateByJWTTokens() {
    const tokens = [this.idToken];
    return tokens.every((token) => this.validateJWTToken(token));
  }

  private validateJWTToken(token: string) {
    try {
      this.jwt.decodeToken(token);
      return true;
    } catch (e) {
      return false;
    }
  }

  private refreshToken(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.browser.isSafari()) {
        /** because safari is not working with 3rd arty cookies and we are getting error */
        this.auth0.authorize({});
      } else {
        this.auth0.checkSession(
          {},
          (err: Auth0Error | null, result: ISession | null) => {
            if (err) {
              console.error(err);
              return reject(err);
            }
            this.setSession(result);
            resolve(true);
          },
        );
      }
    });
  }

  /**
   *
   * @param <Number> expireInInterval time in seconds when token should expires
   * @returns <Number> timestamp in seconds since 1970
   */
  private getExpiresIn = (expireInInterval: number): number =>
    Date.now() / 1000 + expireInInterval;

  private get nonce(): string {
    return this.retrievePreference(Preference.nonce);
  }

  public get isTokenValidByTime(): boolean {
    const expiresIn = this.storageService.getItem(STORAGE_KEYS.EXPIRE);

    if (!expiresIn) {
      return false;
    }

    return Date.now() < Number(expiresIn);
  }

  private removeServerLogoutForSafari() {
    this.storageService.removeItem(STORAGE_KEYS.SAFARI_LOGGED_OUT);
  }

  private setLoggedOutIfSafari() {
    if (this.browser.isSafari()) {
      this.storageService.setItem(STORAGE_KEYS.SAFARI_LOGGED_OUT, 'true');
    }
  }
}
