import { environment } from '@environments/environment';

import { IButton } from './button';

export interface IGlobalUIContext {
  coverImageUrl?: string;
  coverTitle: string;
  coverTitleLink?: string;
  coverSubTitle?: string;
  coverButtons?: Array<IButton>;
  pageClass?: string;
}

export class GlobalUIContext implements IGlobalUIContext {
  coverButtons: Array<IButton> = [];
  coverImageUrl = '';
  coverSubTitle = '';
  coverTitle = '';
  coverTitleLink = '';
  pageClass = '';

  constructor(
    coverTitleOrData:
      | string
      | {
          coverTitle: string;
          coverTitleLink: string;
          coverSubTitle: string;
          coverImageUrl: string;
          coverButtons: string;
          pageClass: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } | any = '', // TODO: fix type
    coverSubTitle?: string,
    coverImageUrl?: string,
    coverButtons?: Array<IButton>,
    pageClass = '',
    coverTitleLink = '',
  ) {
    this.coverTitle = coverTitleOrData.coverTitle || coverTitleOrData;
    this.coverTitleLink = coverTitleOrData.coverTitleLink || coverTitleLink;
    this.coverSubTitle = coverTitleOrData.coverSubTitle || coverSubTitle;
    this.coverImageUrl = coverTitleOrData.coverImageUrl || coverImageUrl;
    this.coverButtons = coverTitleOrData.coverButtons || coverButtons;
    this.pageClass = coverTitleOrData.pageClass || pageClass;

    if (typeof coverTitleOrData !== 'string') {
      this.coverTitle = coverTitleOrData.coverTitle;
    }
    if (
      this.coverImageUrl &&
      !(
        this.coverImageUrl.startsWith('http') ||
        this.coverImageUrl.startsWith('//')
      )
    ) {
      this.coverImageUrl =
        environment.baseUrl +
        'assets/images/covers/' +
        this.coverImageUrl +
        '.jpg';
    }
  }
}
