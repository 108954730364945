import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, merge } from 'rxjs';
import { map, switchMap, shareReplay } from 'rxjs/operators';
import { IGlobalUIContext } from '../models/globalUI.context';
import { GlobalUIContext } from '../models/globalUI.context';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private contextObservables = new BehaviorSubject<
    Observable<IGlobalUIContext>[]
  >([of(new GlobalUIContext('ecoreps', ' ', ''))]);

  public context: Observable<IGlobalUIContext>;

  public subscribeCover(coverObservable: Observable<IGlobalUIContext>): void {
    this.contextObservables.next(
      this.contextObservables.value.concat([coverObservable]),
    );
  }

  public nextContext(context: IGlobalUIContext): void {
    this.contextObservables.next(
      this.contextObservables.value.concat([of(context)]),
    );
  }


  constructor() {
    this.context = this.contextObservables.pipe(
      switchMap((observables) => merge(...observables)),
      map((val) => {
        return val;
      }),
      shareReplay(1),
    );
  }
}
