import { head, isFunction } from 'lodash';

// example
// mapperMap = {
//   createdAt: (date) => moment(date).format("DD.MM.YYYY")
// };
export function prepareCSVFromArray<T>(
  headList: string[] | null,
  data: T[],
  mappersMap = {},
  separator = ',',
): Blob {
  const headers = headList || Object.keys(head(data));
  const replacer = (key, value) => (value === null ? '' : value);
  const csv = data.map((row) =>
          headers
            .map((fieldName) => {
              const value = row[fieldName];
              const mappedValue = isFunction(mappersMap[fieldName])
              ? mappersMap[fieldName](value)
              : value;
              return JSON.stringify(mappedValue, replacer);
            })
            .join(separator),
        );

  const csvArray = [headers.join(separator), ...csv].join('\r\n');

  return new Blob([csvArray], { type: 'text/csv;charset=utf-8;' });
}

export function downloadFileAsCSV(
  data: Blob,
  fileName: string,
): void {
  const downloadLink = document.createElement('a');

  document.body.appendChild(downloadLink);

  downloadLink.download = fileName;
  downloadLink.href = window.URL.createObjectURL(data);
  downloadLink.style.display = 'none';

  downloadLink.click();
}

export function downloadFileAsText(
  data: string,
  fileName: string,
): void {
  const downloadLink = document.createElement('a');

  document.body.appendChild(downloadLink);

  downloadLink.download = fileName;
  downloadLink.href = window.URL.createObjectURL(new Blob([data], { type: 'text/plain;charset=utf-8;' }));
  downloadLink.style.display = 'none';

  downloadLink.click();
}
