/**
 * Created by huck on 15.06.18
 */
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { BehaviorSubject, Observable } from 'rxjs';

import IComment, { CommentVisibility } from '@models/comment';
import { CommentsService } from '@services/comments.service';
import { ModalsService } from '@components/modals/modals.service';
import { IUploadResult, RecordStatus } from '@services/media-capture/';
import { PermissionService } from '@core/auth/permission.service';
import { formatAiMessage } from '@components/section/components/chatbot/chatbot.utils';
import BasicRxComponent from '@components/BasicRxComponent';
import { FeatureFlagService } from '@services/feature-flag.service';
import { ActivityService } from '@services/activity.service';

@Component({
  selector: 'comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  animations: [
    trigger('showRepliesAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('500ms', style({ height: 300, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 300, opacity: 1 }),
        animate('500ms', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.Emulated,
})
export class CommentComponent extends BasicRxComponent implements OnInit {
  @Input() public editMode = false;
  @Input() public comment: IComment = null;
  @Output() public addReply = new EventEmitter<IComment>();
  @Output() public onUpdateComments = new EventEmitter<boolean>();
  @Output() public onSuccessReply = new EventEmitter<boolean>();
  @Output() public onErrorReply = new EventEmitter();

  @ViewChildren('commentsInput') commentInputs: QueryList<
    ElementRef<HTMLTextAreaElement>
  >;

  public replyForm: UntypedFormGroup;
  public showReplies = true;
  public submitted = false;
  public expanded = {};
  public isExpandable = true;
  public CommentVisibility = CommentVisibility;
  public mediaRecordResult$ = new BehaviorSubject<IUploadResult[] | null>(null);
  public commentToEdit: { id: string; text: string } = {
    id: null,
    text: null,
  };

  public mediaResultError$ = new BehaviorSubject<string | null>(null);
  public isFormDisabled = new BehaviorSubject<boolean>(false);
  public isReplyingActivated$ = new BehaviorSubject(false);
  public recommendationsExpanded$ = new BehaviorSubject(false);
  public isMediaCommentEnabled = false;

  constructor(
    private commentService: CommentsService,
    public modalsService: ModalsService,
    public permissionService: PermissionService,
    public featureService: FeatureFlagService,
    public activityService: ActivityService,
  ) {
    super();
    this.replyForm = new UntypedFormGroup({
      text: new UntypedFormControl('', [
        Validators.minLength(6),
        Validators.required,
      ]),
    });
  }

  ngOnInit(): void {
    this.isExpandable = this.comment.replies.length > 0;
    this.recommendationsExpanded$.next(this.comment.replies.length === 0);
    this.bag.add(
      this.permissionService.isAdminRole$.subscribe(this.isReplyingActivated$),
    );
    this.isMediaCommentEnabled = this.featureService.isFeatureEnabled(
      FeatureFlagService.features.MediaComments,
    );
  }

  public autoGrow(element): void {
    element.style.height = '5px';
    element.style.height = element.scrollHeight + 6 + 'px';
  }

  public get currentVisibility(): CommentVisibility {
    return this.comment.visibility || CommentVisibility.admin;
  }

  public get editCommentsAllowed$(): Observable<boolean> {
    return this.permissionService.isAdminRole$;
  }

  public get f(): { [key: string]: AbstractControl } {
    return this.replyForm.controls;
  }

  public responseAuthor(reply: IComment): string {
    return reply.author_name !== 'ecoreps' ? reply.author_name : 'ecoreps';
  }

  public toggleVisibility(): void {
    const current = this.currentVisibility;
    const newVisibility =
      current === CommentVisibility.admin
        ? CommentVisibility.published
        : CommentVisibility.admin;
    this.commentService
      .setVisibility(this.comment._id, newVisibility)
      .subscribe(
        () => {
          this.onUpdateComments.emit(true);
        },
        (error) => {
          console.error(error);
          this.onErrorReply.emit('Fehler beim ändern des Kommentars');
        },
      );
  }

  public onInputFocus() {
    this.activityService.add('focus', 'reply-input');
  }

  public onInputBlur() {
    this.activityService.add('blur', 'reply-input');
  }

  public postReply(): Observable<IComment> {
    if (!this.replyForm.valid) {
      this.onErrorReply.emit('Bitte prüfe dein Kommentar auf Fehler !');
      return;
    }
    const comment = { ...this.replyForm.value } as IComment;
    if (this.mediaRecordResult$.getValue()) {
      comment.media = this.mediaRecordResult$.getValue();
    }
    return this.commentService.postReply(this.comment._id, comment);
  }

  public submit(event: Event): void {
    this.submitted = true;
    if (this.replyForm.valid) {
      this.postReply().subscribe(
        () => {
          this.replyForm.reset();
          const textarea = jQuery(event.target).siblings('textarea');
          if (textarea.length > 0) {
            this.autoGrow(textarea[0]);
          }
          this.onSuccessReply.emit();
          this.onUpdateComments.emit(true);
          this.mediaRecordResult$ = null;
        },
        (error) => {
          console.error(error);
          this.onErrorReply.emit(
            'Fehler beim hochladen deiner Frage: ' + error.message,
          );
        },
      );
    } else {
      this.onErrorReply.emit(
        'Leider ist ein Fehler aufgetreten - bitte überprüfe deine Angaben.',
      );
    }
  }

  public toggleEditableComment(comment: IComment): void {
    if (this.commentToEdit.id !== comment._id) {
      this.commentToEdit = {
        id: comment._id,
        text: comment.text,
      };
    } else {
      this.commentToEdit = {
        id: null,
        text: null,
      };
    }
  }

  public sendToEdit(): void {
    this.commentService
      .modifyComment(this.commentToEdit.id, {
        _text: this.commentToEdit.text,
      })
      .subscribe(() => {
        this.onUpdateComments.emit(true);
      });
  }

  public deleteComment(id: string): void {
    this.commentService.deleteComment(id).subscribe(() => {
      this.onUpdateComments.emit(true);
    });
  }

  public deleteCommentReply(commentId: string, replyId: string): void {
    this.commentService.deleteReply(commentId, replyId).subscribe(() => {
      this.onUpdateComments.emit(true);
    });
  }

  public mediaRecordSuccess(uploadResult: IUploadResult): void {
    const media = this.mediaRecordResult$.getValue() || [];
    this.mediaRecordResult$.next([...media, uploadResult]);
    this.mediaResultError$.next(null);
  }

  public mediaRecordError(errorMessage: string) {
    this.mediaResultError$.next(errorMessage);
  }

  public recordStatusChange(status: RecordStatus) {
    if (status === 'upload') {
      this.isFormDisabled.next(true);
    } else {
      this.isFormDisabled.next(false);
    }
  }

  public activateReplying() {
    this.isReplyingActivated$.next(true);
  }

  public getResponseRecommendations(comment: IComment): string[] {
    return [comment, ...comment.replies]
      .filter((comment) => !!comment.responseRecommendation)
      .map((comment) => comment.responseRecommendation);
  }

  public setReplyText(value: string) {
    this.f.text.setValue(value);
    this.commentInputs.forEach((element) => {
      this.autoGrow(element.nativeElement);
    });
  }

  public formatAiMessage(message: string) {
    return formatAiMessage(message);
  }
}
