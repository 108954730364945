import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProfileService } from './profile.service';
import { CourseService } from './course.service';
import { filter, shareReplay } from 'rxjs/operators';
import { IColumn, ICourse, IExtandedCourse } from '@models/course';
import { CourseNavigationContext, CourseRoute, PageType } from './chapter-navigation.service';
import { IChapter } from '@models/chapter';
import { LoginService } from '@core/auth/login.service';
import { Router } from '@angular/router';
import IProfile from '@models/profile';
import { PermissionService } from '@core/auth/permission.service';
import { DateTime } from 'luxon';
import { createContentUrl } from '@utils/urlFactory';
import { HttpService } from '@core/http';

@Injectable({
  providedIn: 'root',
})
export class SpaService {
  public course = new BehaviorSubject<ICourse>(null);
  public column = new BehaviorSubject<IColumn>(null);
  public chapter = new BehaviorSubject<IChapter>(null);
  public pageType = new BehaviorSubject<PageType>(null);

  constructor(
    private profileService: ProfileService,
    private courseService: CourseService,
    private navContext: CourseNavigationContext,
    private loginService: LoginService,
    private router: Router,
    private permissionService: PermissionService,
    private client: HttpService<unknown>,
  ) {
    this.onInit();
  }

  public addCourseInfo(course: ICourse): IExtandedCourse | undefined {
    if (!course) {
      return;
    }

    const profile = this.profile.getValue();
    const authProfile = this.loginService.profileInfo.getValue();

    if (profile && authProfile) {
      course.isPurchased = this.getPurchased(course);
      course.userData = course.userData || {
        disallowSelfReactivation: undefined,
        isPurchased: course.isPurchased,
        isPurchaseOngoing: profile.courseAccess?.some((access) => {
          if (access.courseId !== course._id) {
            return false;
          }
          if (access.until) {
            const until = DateTime.fromISO(access.until);
            if (until.isValid && DateTime.now() > until) {
              return false;
            }
          }
          return true;
        }),
      };

      if (profile.progress !== undefined) {
        profile.progress.forEach((progress) => {
          if (progress.course_id === course._id) {
            course.totalProgress = progress.progressTotal;
            course.processedProgress = progress.progressProcessedTotal;
          }
        });
      }

      if (profile.courseUserdata) {
        const courseUserdata =
          profile.courseUserdata.find((userdata) => userdata.courseId === course._id);
        if (courseUserdata) {
          course.userData.progress = courseUserdata.progress;
        }
      }
    }
    return course;
  }

  private getPurchased(course: ICourse): boolean {
    return this.getIsPurchased(course._id)
      || this.permissionService.canAccessCourse(course.university.url_slug, course.url_slug);
  }

  private getIsPurchased(courseId: string) {
    const profile = this.profileService.profile.getValue();
    const purchasedCourses = profile.purchasedCourses;
    const courseAccess = profile.courseAccess;
    const tokenVersion = profile.tokenVersion;
    const accessByPurchasedCourses = purchasedCourses?.includes(courseId);
    const accessByCourseAccess = courseAccess?.some((access) => {
      return (access.courseId === courseId &&
        (!access.from || DateTime.fromISO(access.from) < DateTime.now()) &&
        (!access.until || DateTime.fromISO(access.until) > DateTime.now()));
    });
    return (tokenVersion && accessByCourseAccess) ||
      (!tokenVersion && accessByPurchasedCourses);
  }

  public getFirstChapterWithContent(column: IColumn): IChapter | null {
    const res = this.getFirstChapterWithContentRecursive(
      column.chapters as IChapter[],
    );
    const first = column.chapters[0] || null;
    return res || (first as IChapter);
  }

  get profile(): BehaviorSubject<IProfile> {
    return this.profileService.profile;
  }

  get profileNotNull(): Observable<IProfile> {
    return this.profileService.profile.pipe(
      filter((p) => !!p),
      shareReplay(1),
    );
  }

  protected getFirstChapterWithContentRecursive(
    chapters: IChapter[],
    res = null,
  ): IChapter | null {
    chapters.forEach((chapter) => {
      if (res) return;
      if (chapter.hasSections) {
        res = chapter;
      } else if (chapter.subchapters.length) {
        res = this.getFirstChapterWithContentRecursive(
          chapter.subchapters,
          res,
        );
      }
    });

    return res;
  }

  public async orderCourse(course: ICourse): Promise<void> {
    if (this.loginService.loggedInValue) {
      if (course.isPurchased) {
        this.router.navigateByUrl('/courses/' + course.url_slug);
      } else {
        if (this.loginService.isAnonymous.getValue()) {
          this.loginService.loginBookingCourse(course.url_slug, true);
        } else {
          this.router.navigateByUrl('/order/' + course.url_slug);
        }
      }
    } else {
      if (this.loginService.isAnonymous.getValue()) {
        this.loginService.loginBookingCourse(course.url_slug, true);
      } else {
        await this.loginService.getAnonymous();
        this.loginService.loginBookingCourse(course.url_slug, true);
      }
    }
  }

  public clearBackendCacheByKey(key) {
    return this.client.delete(createContentUrl('cache', key));
  }

  public clearBackendCacheStartingWith(key) {
    return this.client.delete(createContentUrl('cache', 'starting-with', key));
  }

  public onInit(): void {
    this.navContext.route.subscribe((route) => {
      this.setCourse(route.course);
      this.setColumn(route);
      this.setChapter(route);
      this.setPageType(route);
    });

    this.profileNotNull.subscribe(() => {
      this.setCourse(this.course.getValue());
    });

    this.loginService.isAdmin.subscribe((isAdmin) => {
      if (isAdmin) {
        this.setCourse(this.course.getValue());
      }
    });

    this.loginService.profileInfo.subscribe(() => {
      this.profileService.me();
    });
  }

  private setChapter(route: CourseRoute): void {
    this.chapter.next(route.chapter);
  }

  private setCourse(course: ICourse): void {
    this.course.next(this.addCourseInfo(course));
  }

  private setColumn(route: CourseRoute): void {
    this.column.next(
      Array.isArray(route.column) ? route.column[0] : route.column,
    );
  }

  private setPageType(route: CourseRoute): void {
    this.pageType.next(route.pageType);
  }
}
