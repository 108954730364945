import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';

import {
  IColumn,
  ICourse, ICourseProgressRequest, ICourseTranscribeRequest, ISearchResult, IUserCourseProgress, SearchType,
} from '@models/course';

import { assignFullNames } from '@utils/chapterUtils';
import { ICourseUserAccessHistoryEntry, IUserAccessState } from '@models/user-access-state';
import IProfile from '@models/profile';

import { HttpService } from '@core/http';
import 'rxjs/add/operator/catch';
import { createContentUrl, createProfileUrl } from '@utils/urlFactory';
import { HttpErrorResponse } from '@angular/common/http';
import { DisallowSelfReactivationState } from '@models/course-userdata';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  public currentCourse$: BehaviorSubject<ICourse | null> =
    new BehaviorSubject<ICourse | null>(null);

  constructor(
    private client: HttpService<ICourse>,
  ) {}

  public bookmarkCourse(courseId: string): Observable<IProfile> {
    return this.client.post<IProfile>(
      createProfileUrl('courses', courseId),
      null,
    );
  }

  public removeBookmark(courseId: string): Observable<IProfile> {
    return this.client.delete<IProfile>(createProfileUrl('courses', courseId));
  }

  public bookmarkedCourses = (): Observable<ICourse[]> => {
    return this.client.get<ICourse[]>(createProfileUrl('courses'));
  };

  public getAccessExportForCourse = (
    courseId: string,
  ): Observable<IUserAccessState[]> => {
    return this.client
      .get<IUserAccessState[]>(createProfileUrl('courses', 'access', courseId.toString()))
      .pipe(
        map((exportedFile) => {
          return exportedFile;
        }),
      )
      .pipe(share());
  };

  public getAccessListForCourse = (
    courseId: string,
  ): Observable<IUserAccessState[]> => {
    return this.client
      .get<IUserAccessState[]>(
        createProfileUrl('courses', 'access', courseId.toString()),
      )
      .pipe(
        map((list) => {
          return list;
        }),
      )
      .pipe(share());
  };

  public removeAllBookmarksFromCourse(course: ICourse) {
    return this.client
      .delete<IUserAccessState[]>(
        createProfileUrl('courses', 'bookmarks', course._id.toString()),
      )
      .pipe(
        map((res) => {
          return res;
        }),
      )
      .pipe(share());
  }

  public revokeAccessForEveryoneInCourse(course: ICourse, reason: string) {
    return this.client
      .delete<{ updated: IUserAccessState[] }>(
        createProfileUrl('courses', 'access', course._id.toString()),
        { body: { reason: reason } },
      )
      .pipe(
        map((res) => {
          return res;
        }),
      )
      .pipe(share());
  }

  public applyPermissionChanges(
    states: IUserAccessState[],
    course: ICourse,
  ): Observable<unknown> {
    return this.client
      .post<IUserAccessState[]>(
        createProfileUrl('courses', 'access', course._id.toString()),
        states,
        undefined,
        () => true,
      )
      .pipe(
        map((res) => {
          return res;
        }),
      )
      .pipe(share());
  }

  public grantCourseToUserId(
    userId: string,
    course: ICourse,
    errorHandler?: (e: HttpErrorResponse) => boolean,
  ): Observable<unknown> {
    return this.client
      .post<{ updated: IUserAccessState[] }>(
        createProfileUrl('courses', 'access', course._id.toString(), userId),
        {
          headers: {
            //"If-Modified-Since": date
          },
        },
        undefined,
        errorHandler,
      )
      .pipe(
        map((res) => {
          return res;
        }),
      )
      .pipe(share());
  }

  public grantCoursesToUsers(
    accessStates: Partial<IUserAccessState>[],
    errorHandler?: (e: HttpErrorResponse) => boolean,
  ): Observable<unknown> {
    return this.client
      .post<unknown>(
        createProfileUrl('courses', 'access'),
        accessStates,
        undefined,
        errorHandler,
      )
      .pipe(
        map((res) => {
          return res;
        }),
      )
      .pipe(share());
  }

  public getCourseById = (
    courseId: string,
    forceReload = false,
    options: {
      skipBackendCache?: boolean;
      adminView?: boolean;
    } = {
      skipBackendCache: false,
      adminView: false,
    },
  ): Observable<ICourse> => {
    const cachedCourse = this.client.getCachedEntity(courseId);
    if (cachedCourse && !forceReload) {
      return of(cachedCourse);
    } else {
      let url = createContentUrl('courses', courseId);
      if (options.adminView) {
        url += '/admin';
      }
      if (options.skipBackendCache) {
        url += '?skipCache=1';
      }
      return this.client.get<ICourse>(url).pipe(
        filter((course: ICourse | undefined) => !!course),
        map((course: ICourse) => {
          if (course?.stats) {
            course.stats.maxProgress =
              course.columns.reduce((sum, column) => sum + (column.stats?.maxProgress || 0), 0);
          }
          for (const col of course.columns) {
            col.chapters = assignFullNames(col.chapters, null, course.columns);
          }
          this.client.addToCache(course);
          return course;
        }),
      );
    }
  };

  public clearCachedCourse(courseId: string) {
    this.client.deleteCachedEntity(courseId);
  }

  public rebuildCourse(course: ICourse): Observable<ICourse> {
    return this.client.get<ICourse>(
      createContentUrl('courses', course.url_slug, 'rebuild'),
    );
  }

  public uploadExamResultProofs(file: unknown, courseId: string): Observable<unknown> {
    return this.client.post<unknown>(createProfileUrl('upload', courseId), file as object, {
      headers: {
          'Access-Control-Allow-Origin': createProfileUrl(),
        },
    }, () => true);
  }

  public changePreviewModeForAllChapters(courseId: string, columnId: string, previewMode: string): Observable<void> {
    return this.client.post(
      createContentUrl('courses', courseId, 'column', columnId, 'preview-mode', previewMode),
      {},
    );
  }

  public exportColumnToLatex = (column: IColumn, courseId: string): Observable<Blob> => {
    return this.client.post(
      createContentUrl('courses', courseId, 'columns', column.id, 'export'),
      {},
      {
        responseType: 'blob' as 'json',
      },
    );
  };

  public importColumnChapters(importCourseId: string, importColumnId: string, intoCourseId: string, intoColumnId: string) {
    return this.client.post(
      createContentUrl('courses', intoCourseId, 'column', intoColumnId, 'import', importCourseId, importColumnId),
      {},
    );
  }

  public getAccessChangeHistoryForCourseAndUser(courseId: string, userId: string) {
    return this.client.get<ICourseUserAccessHistoryEntry[]>(
      createProfileUrl('courses', 'access', courseId, 'history', userId),
    );
  }

  public getAccessForUser(userId: string) {
    return this.client.get<IUserAccessState[]>(
      createProfileUrl('user', userId, 'access'),
    );
  }

  public reEnableCourseWithUndecidedEnrollment(courseId: string) {
    return this.client.post(createProfileUrl('courses', courseId, 're-enable-by-enrollment'), {});
  }

  public getAllCoursesForChatbotAdmin() {
    return this.client.get<ICourse[]>(
      createContentUrl('courses', 'admin', 'for-chatbot'),
    );
  }

  public getAllCoursesForProgressAdmin() {
    return this.client.get<ICourse[]>(
      createContentUrl('courses', 'admin', 'for-progress'),
    );
  }

  public getAllCoursesForListing() {
    return this.client.get<ICourse[]>(
      createContentUrl('courses', 'admin', 'for-listing'),
    );
  }

  public transcribeCourse(courseId: string, payload: ICourseTranscribeRequest) {
    return this.client.post(
      createContentUrl('courses', courseId, 'transcribe'),
      { ...payload },
    );
  }

  public search(courseId: string, query: string, type: SearchType = 'search') {
    return this.client.get<ISearchResult[]>(
      createContentUrl('courses', courseId, 'search') + `?query=${query}&type=${type}`,
    );
  }

  public getProgressForUsersAndCourse(courseId: string, userIds: string[]) {
    return this.client.post<IUserCourseProgress[]>(
      createProfileUrl('userdata', 'course', courseId, 'user-progress'),
      {
        userIds: userIds,
      } as ICourseProgressRequest,
    );
  }

  public toggleDisallowSelfReactivation(courseId: string, access: IUserAccessState) {
    return this.client.post<DisallowSelfReactivationState>(
      createProfileUrl('userdata', 'course', courseId, 'user', access.userId, 'disallow-self-reactivation'),
      {
        disallowSelfReactivation: access.disallowSelfReactivation
          ? DisallowSelfReactivationState.None
          : DisallowSelfReactivationState.All,
      },
    );
  }
}
