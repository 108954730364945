import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { EcospinnerComponent } from './ecospinner.component';

@NgModule({
  declarations: [EcospinnerComponent],
  imports: [CommonModule, RouterModule],
  exports: [EcospinnerComponent],
})
export class EcospinnerModule {}
