/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChapterDisplayMode,
  ChapterTypeInfo,
  IChapter,
} from '@models/chapter';
import { isValidObjectId } from './ObjectId';
import { IColumn } from '@models/course';

export const sortDeleted = (chapter: IChapter[], predicate?) => {
  return !chapter
    ? null
    : chapter.reduce((list, entry) => {
        let clone = null;
        if (predicate(entry)) {
          clone = { ...entry };
        } else if (entry.subchapters !== null) {
          const subchapters = sortDeleted(entry.subchapters, predicate);
          if (subchapters.length > 0) {
            clone = { ...entry, subchapters };
          }
        }
        clone && list.push(clone);
        return list;
      }, []);
};


interface TreeChapter {
  chapter: IChapter;
  pathArray: string[];
}

export function _chapter_tree_from_paths(
  chapters: IChapter[] | TreeChapter[],
  root: string,
  arrayMapped = false,
): TreeChapter[] {
  const arrayMap: TreeChapter[] = arrayMapped
    ? (chapters as TreeChapter[]).map((treechapter) => {
        treechapter.pathArray.splice(0, 1);
        return treechapter;
      })
    : (chapters as IChapter[])
        .map((chapter) => {
          const path = chapter.path.split(',');
          if (!path || path.length == 0 || path[0] != root) {
            return null;
          }
          path.splice(0, 1);
          return {
            chapter: chapter,
            pathArray: path,
          };
        })
        .filter(Boolean);
  if (arrayMapped) {
    return chapters as TreeChapter[];
  }
  let roots = (arrayMap as TreeChapter[]).filter(
    (c) => c.pathArray.length == 0,
  );
  roots = roots.map((rootTreeChapter) => {
    const rootChapter = rootTreeChapter.chapter;
    const rootKey = rootChapter.url_slug;

    const filtered_sub = arrayMap.filter((chapter) => {
      return chapter.pathArray.length > 0 && rootKey == chapter.pathArray[0];
    });
    const subchapters = _chapter_tree_from_paths(filtered_sub, rootKey, true);

    (rootChapter as any).set(
      'subchapters',
      subchapters.map((c) => c.chapter),
      { strict: 'throw' },
    );
    return {
      chapter: rootChapter,
      pathArray: rootTreeChapter.pathArray,
    };
  });
  return roots;
}

export function flatten(chapters: IChapter[], startIndex: ChapterIndex = null) {
  if (!chapters || chapters.length == 0) {
    return [];
  }
  let flattened: IChapter[] = [];
  let index = startIndex;

  for (const chap of chapters) {
    let branched = null;
    if (index) {
      chap.index = index;
      branched = index.branched();
      let _subindex = branched;
      if (chap.subchapters && chap.subchapters.length > 0) {
        for (const sub of chap.subchapters) {
          sub.index = _subindex;
          _subindex = _subindex.nextSibling;
        }
      }
    }
    flattened.push(chap);
    if (
      chap.subchapters != undefined &&
      chap.subchapters != null &&
      chap.subchapters.length > 0
    ) {
      flattened = flattened.concat(flatten(chap.subchapters, branched));
    }
    if (index) {
      index = index.nextSibling;
    }
  }

  return flattened;
}

export function hasSections(chapter: IChapter) {
  return (
    chapter.hasSections || (chapter.sections && chapter.sections.length > 0)
  );
}

export function assignFullNames(
  chapters: IChapter[],
  startIndex: ChapterIndex,
  columns: IColumn[],
): IChapter[] {
  let index = startIndex;
  for (const chapter of chapters) {
    let branched = null;
    const column = columns.find(
      (c) => (c as any).id.toString() == chapter.columnId.toString(),
    );
    if (!column) {
      alert(
        'cant find col with id: ' +
          chapter.columnId +
          'of chapter: ' +
          JSON.stringify(chapter),
      );
    }
    const typeInfo = new ChapterTypeInfo(column);
    if (index) {
      chapter.index = index;
      branched = index.branched();
    } else if (chapter.orderString) {
      chapter.index = new ChapterIndex(
        chapter.orderString.split(',').map((i) => parseInt(i)),
      );
      //chapter.fullTitle = chapter.index.description + " " + chapter.title;
    }
    chapter.fullTitle = typeInfo.showChapterIndizes
      ? chapter.index.description + ' ' + chapter.title
      : chapter.title;
    if (chapter.subchapters != undefined && chapter.subchapters.length > 0) {
      chapter.subchapters = assignFullNames(
        chapter.subchapters,
        index ? branched : null,
        columns,
      );
    }
    if (index) {
      index = index.nextSibling;
    }
  }
  return chapters;
}

export function findChapterContainingDisplay(
  chapter: IChapter,
  chapters: IChapter[],
): IChapter {
  if (chapter.displayMode == ChapterDisplayMode.includedInParent) {
    const parent = findParentChapterOf(chapter, chapters);

    if (parent) {
      return findChapterContainingDisplay(parent, chapters);
    } else {
      console.error('cant find parent chapter');
      console.error([chapter, flatten(chapters)]);
    }
  }
  return chapter;
}

export function findChapterByIdInChapters(
  chapterId: string,
  chapters: IChapter[],
  recursive = true,
): IChapter {
  const field: string = isValidObjectId(chapterId) ? '_id' : 'url_slug';

  if (chapters.length == 0) {
    return null;
  }

  for (const chapter of chapters) {
    if (chapter[field] === chapterId) {
      return chapter;
    }
    if (recursive && chapter.subchapters) {
      const subChapter = findChapterByIdInChapters(
        chapterId,
        chapter.subchapters as IChapter[],
        true,
      );
      if (subChapter != null && !(subChapter == undefined)) {
        return subChapter;
      }
    }
  }

  return null;
}

export function applyHierarchical(
  chapters: IChapter[],
  closure: (parent: IChapter, child: IChapter) => void,
  parent: IChapter = null,
) {
  if (!chapters) {
    return;
  }
  for (const chap of chapters) {
    closure(parent, chap);
    applyHierarchical(chap.subchapters, closure, chap);
  }
}

export function findParentChapterOf(
  chapter: IChapter,
  chapters: IChapter[],
): IChapter {
  const flattened = flatten(chapters);
  const parent = flattened.find((chap) => {
    return chap.subchapters.find((sub) => sub._id == chapter._id) !== undefined;
  });
  return parent;
}

export class ChapterIndex {
  public get description(): string {
    return this.values.join('.');
  }

  public get level(): number {
    return this.values.length - 1;
  }

  public static startIndex(): ChapterIndex {
    return new ChapterIndex([1]);
  }

  public indexAtLevel(level: number): number {
    return this.values[level];
  }

  public contains(maybeChild: ChapterIndex): boolean {
    if (maybeChild.level < this.level) {
      return false;
    }

    for (const i in this.values) {
      if (
        this.indexAtLevel(parseInt(i)) != maybeChild.indexAtLevel(parseInt(i))
      ) {
        return false;
      }
    }
    return true;
  }

  private beginAt = 1;

  public enhanced = (level: number, by = 1) => {
    if (level > this.level || level < 0) {
      throw new Error(
        'Tried to enhanceindex at level that is too high or too low ',
      );
    }
    const resultValues: number[] = [];

    for (const i in this.values) {
      if (parseInt(i) === level) {
        resultValues[i] = this.values[i] + by;
      } else {
        resultValues[i] = this.values[i];
      }
    }
    return new ChapterIndex(resultValues);
  };

  public get nextSibling(): ChapterIndex {
    return this.enhanced(this.level, 1);
  }

  public get previousSibling(): ChapterIndex {
    const enh = this.enhanced(this.level, -1);
    return enh;
  }

  public get nextChild(): ChapterIndex {
    return this.branched();
  }

  public branched = () => {
    const resultValues: number[] = [];

    for (const i in this.values) {
      resultValues[i] = this.values[i];
    }
    resultValues[this.values.length] = this.beginAt;
    return new ChapterIndex(resultValues);
  };

  public get levels(): number[] {
    return this.values;
  }

  public constructor(private values: number[]) {}
}

export const findChapter = (
  chapters: IChapter[],
  urlSlug: string,
): IChapter | null => {
  // TODO: optimize by adding caching
  for (const item of chapters) {
    if (item.url_slug === urlSlug) {
      return item;
    }
    if (item.subchapters?.length) {
      const found = findChapter(item.subchapters, urlSlug);
      if (found) {
        return found;
      }
    }
  }
  return null;
};
