import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from './http.service';
import { AUTHENTICATED } from './http.context';

@Injectable({
  providedIn: 'root',
})
export class HealthCheckService {
  private interval: NodeJS.Timeout;
  private requestTimeRange = 240000;
  private requestURL = 'https://status.ecoreps.de';
  public navigationOpen = new BehaviorSubject<boolean>(true);

  constructor(
    private httpService: HttpService<{ isUp: boolean }>,
    private router: Router,
  ) {}

  onInit() {
    this.sendRequest();
    this.startHealthCheckInterval();
  }

  startHealthCheckInterval() {
    this.interval = setInterval(() => {
      this.sendRequest();
    }, this.requestTimeRange);
  }

  sendRequest() {
    const authContext = new HttpContext().set(AUTHENTICATED, false);

    const subscription = this.httpService
      .get(this.requestURL, { context: authContext })
      .pipe(
        catchError((e) => {
          return of(e);
        }),
      )
      .subscribe(({ isUp }: { isUp: boolean }) => {
        if (isUp === false) {
          clearInterval(this.interval);
          this.router.navigate(['health-check-error']).finally(() => {
            this.navigationOpen.next(false);
          });
        }
        subscription.unsubscribe();
      });
  }
}
