<div id="page-container" [class]="(cssClass | async)">
  <topbar *ngIf="(topBar | async).show"></topbar>
  <div class="app-content" [class.with-sidebar]="(sideBar | async).show">
    <sidebar *ngIf="(sideBar | async).show"></sidebar>
    <router-outlet></router-outlet>
  </div>
  <footer-component *ngIf="(topBar | async).show"></footer-component>
  <div id="modalContainer" class="modal-container"></div>
</div>

<app-dialog
  #sessionExpiredModal
  [id]="modalService.MODAL_IDS.SESSION_EXPIRED"
  [closable]="false"
  [title]="'Deine Sitzung ist abgelaufen'"
  [submitText]="'Erneut einloggen'"
  [cancelText]="'Zurück zur Landingpage'"
  (onSubmit)="reLogin()"
  (onCancel)="logout()"
>
  <p>
    Dies kann passieren, wenn du ecoreps für einige Zeit nicht benutzt hast,
    oder in einem anderen Browser-Tab ausgeloggt wurdest.
  </p>
  <p>
    Du kannst dich erneut einloggen um eine neue Sitzung zu beginnen.
  </p>
</app-dialog>

<app-dialog
  #userBlockedModal
  [id]="modalService.MODAL_IDS.DYNAMIC_MODAL"
  [closable]="false"
  [submittable]="false"
  [cancelable]="false"
  [title]="'Dein Nutzerkonto ist gesperrt'"
>
  <p>
    Dein Nutzerkonto wurde aus folgendem Grund vorübergehend gesperrt:<br>
    <strong>{{ renderBlockReason() }}</strong>
  </p>
  <p>
    Wende dich über das <a href="/contact">Kontaktformular</a>,
    per E-Mail an <a href="mailto:orga{{'@'}}ecoreps.de">orga{{'@'}}ecoreps.de</a>,
    oder Whatsapp an <a href="https://wa.me/+4916091360690" target="_blank">+49 160 91360690</a>
    bei unserem Orga-Team, um den Sachverhalt zu klären und dein Nutzerkonto wieder freizuschalten.
  </p>
</app-dialog>

<div [class]="dontwaste"></div>
