import { Injectable } from '@angular/core';
import { IPrice } from '@models/course';

@Injectable({
  providedIn: 'root',
})
export class PriceFormatService {
  public formattedPrice(price: number, currency?: string): string {
    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: { Euro: 'EUR' }[currency] || currency || 'EUR',
    });
    return formatter.format(price / 100);
  }

  public formatted(price: IPrice): string {
    return this.formattedPrice(price.price, price.currency);
  }
}
