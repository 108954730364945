import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ROLES_URL } from './auth.constants';
import { Role, RoleSchematics } from './types';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private roles$ = new BehaviorSubject<string[]>([]);
  private adminRoles$ = new BehaviorSubject<string[]>([]);
  private isAdmin$ = new BehaviorSubject<boolean>(false);

  constructor(private loginService: LoginService) {
    this.loginService.profileInfo
      .pipe(
        map((profile) =>
          profile && profile[ROLES_URL] ? profile[ROLES_URL] : [],
        ),
        map((roles) =>
          roles
            .filter((role) => typeof role === 'string')
            .map((role) => role.toLowerCase()),
        ),
      )
      .subscribe(this.roles$);

    this.roles$
      .pipe(
        map((roles) => roles.filter((role) => role.startsWith(Role.Admin))),
        map((roles) =>
          roles.filter((role) => role.replace(`^${Role.Admin}:`, '')),
        ),
      )
      .subscribe(this.adminRoles$);

    this.adminRoles$
      .pipe(map((roles) => roles.length > 0))
      .subscribe(this.isAdmin$);
  }

  public get isAdminRole$() {
    return this.isAdmin$.asObservable();
  }

  public canAccessUniversity$(slug: string): Observable<boolean> {
    return this.rolesIncludes$([
      Role.Admin,
      this.buildParameter(Role.University, slug),
    ]);
  }

  public canAccessUniversity(slug: string): boolean {
    return this.rolesIncludes({
      roles: this.roles$.getValue(),
      topics: [Role.Admin, this.buildParameter(Role.University, slug)],
    });
  }

  public canAccessCourse$(uni: string, course: string): Observable<boolean> {
    return this.rolesIncludes$([
      Role.Admin,
      this.buildParameter(Role.University, uni),
      this.buildParameter(Role.Course, course),
    ]);
  }

  public canAccessCourse(uni: string, course: string): boolean {
    return (
      this.rolesIncludes({
        roles: this.roles$.getValue(),
        topics: [
          Role.Admin,
          this.buildParameter(Role.University, uni),
          this.buildParameter(Role.Course, course),
        ],
      }) ||
      this.canAccessCourseContent(uni, course) ||
      this.canAccessCourseAccessList(uni, course) ||
      this.canAccessCourseComments(uni, course)
    );
  }

  public canAccessCourseContent(uni: string, course: string): boolean {
    return (
      this.rolesIncludes({
        roles: this.roles$.getValue(),
        topics: [
          Role.Admin,
          this.buildParameter(Role.University, uni),
          this.buildParameter(Role.Course, course),
          Role.Content,
        ],
      }) ||
      this.rolesIncludes({
        roles: this.roles$.getValue(),
        topics: [
          Role.Admin,
          this.buildParameter(Role.University, uni),
          Role.Content,
        ],
      })
    );
  }

  public canAccessCourseContent$(
    uni: string,
    course: string,
  ): Observable<boolean> {
    return combineLatest([
      this.rolesIncludes$([
        Role.Admin,
        this.buildParameter(Role.University, uni),
        this.buildParameter(Role.Course, course),
        Role.Content,
      ]),
      this.rolesIncludes$([
        Role.Admin,
        this.buildParameter(Role.University, uni),
        Role.Content,
      ]),
    ]).pipe(map(([byCourse, byUniversity]) => byCourse || byUniversity));
  }

  public canAccessCourseComments(uni: string, course: string): boolean {
    return (
      this.rolesIncludes({
        roles: this.roles$.getValue(),
        topics: [
          Role.Admin,
          this.buildParameter(Role.University, uni),
          this.buildParameter(Role.Course, course),
          Role.Comments,
        ],
      }) ||
      this.rolesIncludes({
        roles: this.roles$.getValue(),
        topics: [
          Role.Admin,
          this.buildParameter(Role.University, uni),
          Role.Comments,
        ],
      })
    );
  }

  public canAccessCourseAccessList(
    universitySlug: string,
    courseSlug: string,
  ): boolean {
    return (
      this.rolesIncludes({
        roles: this.roles$.getValue(),
        topics: [
          Role.Admin,
          this.buildParameter(Role.University, universitySlug),
          this.buildParameter(Role.Course, courseSlug),
          Role.Access,
        ],
      }) ||
      this.rolesIncludes({
        roles: this.roles$.getValue(),
        topics: [
          Role.Admin,
          this.buildParameter(Role.University, universitySlug),
          Role.Access,
        ],
      })
    );
  }

  public canAccessUniversitiesAdmin$(): Observable<boolean> {
    return this.canAccessUniversity$(RoleSchematics.Wildcard);
  }

  public canAccessAccountingAdmin$(): Observable<boolean> {
    return this.rolesIncludes$([Role.Admin, Role.Accounting]);
  }

  public canAccessSelfReactivationAdmin$(): Observable<boolean> {
    return this.rolesIncludes$([Role.Admin, Role.SelfReactivation]);
  }

  public canAccessCronAdmin$(): Observable<boolean> {
    return this.rolesIncludes$([Role.Admin, Role.Cron]);
  }

  public canAccessReviewsAdmin$(): Observable<boolean> {
    return this.rolesIncludes$([Role.Admin, Role.Reviews]);
  }

  public canAccessNotificationAdmin$(): Observable<boolean> {
    return this.rolesIncludes$([Role.Admin, Role.Notifications]);
  }

  public canAccessChatbotAdmin$(): Observable<boolean> {
    return this.rolesIncludes$([Role.Admin, Role.Chatbot]);
  }

  public canAccessVouchersAdmin$(): Observable<boolean> {
    return this.rolesIncludes$([Role.Admin, Role.Vouchers]);
  }

  public canAccessCommentsAdmin$(): Observable<boolean> {
    return this.rolesIncludes$([Role.Admin, Role.CommentDashboard]);
  }

  public canAccessUsersAdmin$(): Observable<boolean> {
    return this.rolesIncludes$([Role.Admin, Role.Users]);
  }

  public canAccessImagesAdmin$(): Observable<boolean> {
    return this.rolesIncludes$([Role.Admin, Role.Images]);
  }

  public canAccessProgressAdmin$(): Observable<boolean> {
    return this.rolesIncludes$([Role.Admin, Role.Progress]);
  }

  public hasExactRole$(key: string) {
    return this.roles$.pipe(
      map((roles) => roles.includes(key)),
    );
  }

  protected rolesIncludes$(topics: string[]): Observable<boolean> {
    return this.roles$.pipe(
      map((roles) => this.rolesIncludes({ roles, topics })),
    );
  }

  // todo: replace the following with shared code
  // https://gitlab.com/huck-it/ecoreps/poc/-/wikis/User-Permissions
  private rolesIncludes(parameters: {
    roles: string[];
    topics: string[];
  }): boolean {
    // Check if any permission exists in the roles array that satisfies the requested topics
    return parameters.roles.some((role) => {
      const roleParts = role.split(RoleSchematics.TopicSeparator);

      // Permission check is satisfied, if every topic of the checked permission satisfies the corresponding topic from
      // the topics array
      return roleParts.every((rolePart, index) => {
        if (parameters.topics.length <= index) {
          // The requested topics might be less specific. At this point every preceding topic was already satisfied, so
          // we can stop checking here: permission 'admin:uni/konstanz' satisfies 'admin:uni/konstanz:content'
          return true;
        }
        // check every topic from current role with topic from parameter
        const topic = parameters.topics[index];

        // Replace definition wildcard (*) with regex wildcard (.*) while respecting beginning and end of string (^$)
        const topicRegex = `^${topic.replaceAll(
          RoleSchematics.Wildcard,
          '.*',
        )}$`;
        const roleRegex = `^${rolePart.replaceAll(
          RoleSchematics.Wildcard,
          '.*',
        )}$`;

        // If the roles topic matched the requested topic, or the other way around, return true. We check both directions
        // due to wildcards in either role definition or requested topic.
        // role 'admin:uni/*' satisfies requested permission to 'admin:uni/konstanz'
        // role 'admin:uni/konstanz' satisfies requested permission to 'admin:uni/*'
        return !!(rolePart.match(topicRegex) || topic.match(roleRegex));
      });
    });
  }

  private buildParameter(...part: string[]): string {
    return part.join(RoleSchematics.ParameterSeparator);
  }
}
