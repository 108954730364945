import { ICourse } from './course';
import { ICourseUserdata } from '@models/course-userdata';
import { IUserBlock } from '@modules/admin-access-modules/users/users.types';

export enum ProgressState {
  undefined = 0,
  notUnderstood = 1,
  partlyUnderstood = 2,
  fullyUnderstood = 3
}


export const AllPossibleProgressStates = [
  ProgressState.undefined,
  ProgressState.notUnderstood,
  ProgressState.partlyUnderstood,
  ProgressState.fullyUnderstood,
];

export interface ISectionProgress {
  section: string;
  state: ProgressState;
}

export interface IProgressChapterEntry {
  chapter: string;
  sections: ISectionProgress[];
  state: ProgressState;
  pathTitle?: string;
  enrollment: boolean;
}

export interface IColumnProgress {
  columnId: string;
  progressUnderstood: number;
  progressProcessed: number;
  progressUnderstoodOthers?: number;
  progressProcessedOthers?: number;
  chapterProgress: IProgressChapterEntry[];
  includeInAggregations?: boolean;
}

export interface IProgressAggregation {
  progressProcessed: number;
  progressUnderstood: number;
}
export interface IProgress {
  course_id: string;
  progressTotal: number;
  columnProgress: IColumnProgress[];
  progressProcessedTotal: number;
}

export interface ICourseAccess {
  courseId: string;
  from?: string;
  until?: string;
}

export default interface IProfile {
  userId: string;
  progress: IProgress[];
  statusDescription?: string;
  last_login: number;
  bookmarkedCourses: ICourse[];
  mainUniversity?: { id: string; url_slug: string; name: string };
  purchasedCourses?: string[];
  courseAccess?: ICourseAccess[];
  tokenVersion?: string;
  courseUserdata: ICourseUserdata[];
  blocks?: IUserBlock[];
}

export interface ICompletionRequest {
  state: ProgressState;
  chapterId: string;
  sectionId: string;
  unset?: boolean;
}
