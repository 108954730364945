import { Preference } from '@models/preference';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ILoginState {
  bookmarkCourse: string;
}

export interface IAuthService {
  profileInfo: BehaviorSubject<IAuthProfile>;
  isAdmin: Observable<boolean>;
  currentIsAdmin: boolean;
  loginstate: ILoginState;
  isReady: BehaviorSubject<boolean>;
  isLoggedIn: BehaviorSubject<boolean>;
  isAnonymous: BehaviorSubject<boolean>;
  loggedInValue: boolean;
  idToken: string;
  hasPurchasedCourse(courseId: string): boolean;
  storePreference(preference: Preference, value: string): void;
  storePreferenceForImmediateKey(preference: string, value: string): void;
  retrievePreferenceByImmediateKey(preference: string): string;
  retrievePreference(preference: Preference): string;
  reLoginRestoringState();
  loginBookingCourse(course: string, register: boolean): void;
  login(
    nonce: string,
    state: ILoginState,
    register: boolean
  ): Promise<void> | undefined;
  logout(redirect: boolean): void;
  getAnonymous(): Promise<void>;
  handleSessionChangeIfNecessary(): void;
}

export interface IAuthProfile {
  at_hash: string;
  aud: string;
  email: string;
  email_verified: boolean;
  exp: number;
  'https://ecoreps.de/purchased_courses': [string];
  'https://ecoreps.de/courseAccess': [{
    courseId: string;
    from?: string;
    until?: string;
  }];
  'https://ecoreps.de/roles': string[];
  'https://ecoreps.de/version': string;
  iat: number;
  iss: string;
  name: string;
  nickname: string;
  nonce: string;
  picture: string;
  sub: string;
  updated_at: string;
  anonymousId?: string;
}

// Schematics used in permission definitions: https://gitlab.com/huck-it/ecoreps/poc/-/wikis/User-Permissions
export enum RoleSchematics {
  TopicSeparator = ':',
  ParameterSeparator = '/',
  Wildcard = '*',
}

export enum Role {
  Admin = 'admin',
  Reviews = 'reviews',
  Accounting = 'accounting',
  Notifications = 'notifications',
  Cron = 'cron',
  University = 'uni',
  Course = 'course',
  Content = 'content',
  Comments = 'comments',
  Access = 'access',
  SelfReactivation = 'self-reactivation',
  Chatbot = 'chatbot',
  Vouchers = 'vouchers',
  CommentDashboard = 'commentdashboard',
  Users = 'users',
  Progress = 'progress',
  Images = 'images',
}
